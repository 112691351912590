import React from 'react';
import HiddenPageTemplate from '../templates/post';

const DataPage: React.FC = () => {
  return (
    <HiddenPageTemplate pageContext={{ title: 'Datenschutz' }}>
      <>
        <p>
          Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere
          der EU-Datenschutzgrundverordnung (DSGVO), ist:
        </p>
        <p>
          Johannes Hinterstößer
          <br />
          hello@jhinter.de
        </p>

        <h2>Ihre Betroffenenrechte</h2>

        <p>
          Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
          können Sie jederzeit folgende Rechte ausüben:
        </p>

        <ul>
          <li>
            Auskunft über Ihre bei uns gespeicherten Daten und deren
            Verarbeitung (Art. 15 DSGVO),
          </li>
          <li>
            Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
          </li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
          <li>
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
            gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
          </li>
          <li>
            Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
            DSGVO) und
          </li>
          <li>
            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
            (Art. 20 DSGVO).
          </li>
        </ul>

        <p>
          Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
          jederzeit mit Wirkung für die Zukunft widerrufen.
        </p>

        <p>
          Sie können sich jederzeit mit einer Beschwerde an eine
          Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des
          Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche
          Stelle zuständige Behörde.
        </p>

        <p>
          Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
          mit Anschrift finden Sie unter:&nbsp;
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
          .
        </p>

        <h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
          registrieren oder anderweitig Informationen übermitteln, werden
          automatisch Informationen allgemeiner Natur erfasst. Diese
          Informationen (Server-Logfiles) beinhalten etwa die Art des
          Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
          Internet-Service-Providers, Ihre IP-Adresse und ähnliches.
        </p>

        <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>

        <ul>
          <li>
            Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
          </li>
          <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
          <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
          <li>zu weiteren administrativen Zwecken.</li>
        </ul>

        <p>
          Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
          ziehen. Informationen dieser Art werden von uns ggfs. statistisch
          ausgewertet, um unseren Internetauftritt und die dahinterstehende
          Technik zu optimieren.
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
          unseres berechtigten Interesses an der Verbesserung der Stabilität und
          Funktionalität unserer Website.
        </p>

        <h3>Empfänger:</h3>

        <p>
          Empfänger der Daten sind ggf. technische Dienstleister, die für den
          Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig
          werden.
        </p>

        <h3>Speicherdauer:</h3>

        <p>
          Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
          nicht mehr erforderlich sind. Dies ist für die Daten, die der
          Bereitstellung der Webseite dienen, grundsätzlich der Fall, wenn die
          jeweilige Sitzung beendet ist.
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
          gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist
          jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
          gewährleistet. Zudem können einzelne Dienste und Services nicht
          verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein
          Widerspruch ausgeschlossen.
        </p>

        <h2>Cookies</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.
          Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem
          Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn
          Sie unsere Webseite besuchen.
        </p>

        <p>
          Hierdurch erhalten wir bestimmte Daten wie z. B. IP-Adresse,
          verwendeter Browser und Betriebssystem.
        </p>

        <p>
          Cookies können nicht verwendet werden, um Programme zu starten oder
          Viren auf einen Computer zu übertragen. Anhand der in Cookies
          enthaltenen Informationen können wir Ihnen die Navigation erleichtern
          und die korrekte Anzeige unserer Webseiten ermöglichen.
        </p>

        <p>
          In keinem Fall werden die von uns erfassten Daten an Dritte
          weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit
          personenbezogenen Daten hergestellt.
        </p>

        <p>
          Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
          betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie
          Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von
          Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren.
          Bitte verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu
          erfahren, wie Sie diese Einstellungen ändern können. Bitte beachten
          Sie, dass einzelne Funktionen unserer Website möglicherweise nicht
          funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.
        </p>

        <h3>Speicherdauer und eingesetzte Cookies:</h3>

        <p>
          Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die
          Verwendung von Cookies erlauben, können folgende Cookies auf unseren
          Webseiten zum Einsatz kommen:
        </p>

        <p>
          Soweit diese Cookies (auch) personenbezogene Daten betreffen können,
          informieren wir Sie darüber in den folgenden Abschnitten.
        </p>

        <p>
          Sie können über Ihre Browsereinstellungen einzelne Cookies oder den
          gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie
          Informationen und Anleitungen, wie diese Cookies gelöscht oder deren
          Speicherung vorab blockiert werden können. Je nach Anbieter Ihres
          Browsers finden Sie die notwendigen Informationen unter den
          nachfolgenden Links:
        </p>

        <ul>
          <li>
            Mozilla Firefox:&nbsp;
            <a
              href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen
            </a>
          </li>
          <li>
            Internet Explorer:&nbsp;
            <a
              href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </li>
          <li>
            Google Chrome:&nbsp;
            <a
              href="https://support.google.com/accounts/answer/61416?hl=de"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://support.google.com/accounts/answer/61416?hl=de
            </a>
          </li>
          <li>
            Opera:&nbsp;
            <a
              href="http://www.opera.com/de/help"
              target="_blank"
              rel="noreferrer noopener"
            >
              http://www.opera.com/de/help
            </a>
          </li>
          <li>
            Safari:&nbsp;
            <a
              href="https://support.apple.com/kb/PH17191?locale=de_DE&amp;viewlocale=de_DE"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://support.apple.com/kb/PH17191?locale=de_DE&amp;viewlocale=de_DE
            </a>
          </li>
        </ul>

        <h2>Registrierung auf unserer Website</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Bei der Registrierung für die Nutzung unserer personalisierten
          Leistungen werden einige personenbezogene Daten erhoben, wie Name,
          Anschrift, Kontakt- und Kommunikationsdaten (z. B. Telefonnummer und
          E-Mail-Adresse). Sind Sie bei uns registriert, können Sie auf Inhalte
          und Leistungen zugreifen, die wir nur registrierten Nutzern anbieten.
          Angemeldete Nutzer haben zudem die Möglichkeit, bei Bedarf die bei
          Registrierung angegebenen Daten jederzeit zu ändern oder zu löschen.
          Selbstverständlich erteilen wir Ihnen darüber hinaus jederzeit
          Auskunft über die von uns über Sie gespeicherten personenbezogenen
          Daten.
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
          auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a
          DSGVO).
        </p>

        <p>
          Dient die Registrierung der Erfüllung eines Vertrages, dessen
          Vertragspartei die betroffene Person ist oder der Durchführung
          vorvertraglicher Maßnahmen, so ist zusätzliche Rechtsgrundlage für die
          Verarbeitung der Daten Art. 6 Abs. 1 lit. b DSGVO.
        </p>

        <h3>Empfänger:</h3>

        <p>
          Empfänger der Daten sind ggf. technische Dienstleister, die für den
          Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig
          werden.
        </p>

        <h3>Speicherdauer:</h3>

        <p>
          Daten werden in diesem Zusammenhang nur verarbeitet, solange die
          entsprechende Einwilligung vorliegt. Danach werden sie gelöscht,
          soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur
          Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende
          dieser Datenschutzerklärung angegebenen Kontaktdaten.
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
          allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer
          personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere
          angebotenen Inhalte und Leistungen gewähren.
        </p>

        <h2>Kommentarfunktion</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Wenn Nutzer Kommentare auf unserer Website hinterlassen, werden neben
          diesen Angaben auch der Zeitpunkt ihrer Erstellung und der zuvor durch
          den Websitebesucher gewählte Nutzername gespeichert. Dies dient
          unserer Sicherheit, da wir für widerrechtliche Inhalte auf unserer
          Webseite belangt werden können, auch wenn diese durch Benutzer
          erstellt wurden.
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Die Verarbeitung der als Kommentar eingegebenen Daten erfolgt auf der
          Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).
        </p>

        <p>
          Durch Bereitstellung der Kommentarfunktion möchten wir Ihnen eine
          unkomplizierte Interaktion ermöglichen. Ihre gemachten Angaben werden
          zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
          Anschlussfragen gespeichert.
        </p>

        <h3>Empfänger:</h3>

        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>

        <h3>Speicherdauer:</h3>

        <p>
          Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
          nicht mehr erforderlich sind. Dies ist grundsätzlich der Fall, wenn
          die Kommunikation mit dem Nutzer abgeschlossen ist und das Unternehmen
          den Umständen entnehmen kann, dass der betroffene Sachverhalt
          abschließend geklärt ist.
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
          Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen
          keinen Zugang zu unserer Kommentarfunktion gewähren.
        </p>

        <h2>Newsletter</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Ihre Daten werden ausschließlich dazu verwendet, Ihnen den abonnierten
          Newsletter per E-Mail zuzustellen. Die Angabe Ihres Namens erfolgt, um
          Sie im Newsletter persönlich ansprechen zu können und ggf. zu
          identifizieren, falls Sie von Ihren Rechten als Betroffener Gebrauch
          machen wollen.
        </p>

        <p>
          Für den Empfang des Newsletters ist die Angabe Ihrer E-Mail-Adresse
          ausreichend. Bei der Anmeldung zum Bezug unseres Newsletters werden
          die von Ihnen angegebenen Daten ausschließlich für diesen Zweck
          verwendet. Abonnenten können auch über Umstände per E-Mail informiert
          werden, die für den Dienst oder die Registrierung relevant sind (bspw.
          Änderungen des Newsletterangebots oder technische Gegebenheiten).
        </p>

        <p>
          Für eine wirksame Registrierung benötigen wir eine valide
          E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung tatsächlich
          durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das
          „Double-opt-in“-Verfahren ein. Hierzu protokollieren wir die
          Bestellung des Newsletters, den Versand einer Bestätigungsmail und den
          Eingang der hiermit angeforderten Antwort. Weitere Daten werden nicht
          erhoben. Die Daten werden ausschließlich für den Newsletterversand
          verwendet und nicht an Dritte weitergegeben.
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1
          lit. a DSGVO), übersenden wir Ihnen regelmäßig unseren Newsletter bzw.
          vergleichbare Informationen per E-Mail an Ihre angegebene
          E-Mail-Adresse.
        </p>

        <p>
          Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer
          Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für
          die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein
          entsprechender Link. Außerdem können Sie sich jederzeit auch direkt
          auf dieser Website abmelden oder uns Ihren Widerruf über die am Ende
          dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.
        </p>

        <h3>Empfänger:</h3>

        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>

        <h3>Speicherdauer:</h3>

        <p>
          Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die
          entsprechende Einwilligung vorliegt. Danach werden sie gelöscht.
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
          allein auf Basis Ihrer Einwilligung. Ohne bestehende Einwilligung
          können wir Ihnen unseren Newsletter leider nicht zusenden.
        </p>

        <h2>Kontaktformular</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
          Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer
          validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient
          der Zuordnung der Anfrage und der anschließenden Beantwortung
          derselben. Die Angabe weiterer Daten ist optional.
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
          auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f
          DSGVO).
        </p>

        <p>
          Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine
          unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben
          werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
          Anschlussfragen gespeichert.
        </p>

        <p>
          Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen,
          erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten
          zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b
          DSGVO).
        </p>

        <h3>Empfänger:</h3>

        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>

        <h3>Speicherdauer:</h3>

        <p>
          Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage
          gelöscht.
        </p>

        <p>
          Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den
          gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach
          Ablauf dieser Fristen.
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
          Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren
          Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
        </p>

        <h2>Verwendung von Google Analytics </h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA
          (nachfolgend: „Google“). Google Analytics verwendet sog. „Cookies“,
          also Textdateien, die auf Ihrem Computer gespeichert werden und die
          eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die
          durch das Cookie erzeugten Informationen über Ihre Benutzung dieser
          Webseite werden in der Regel an einen Server von Google in den USA
          übertragen und dort gespeichert. Aufgrund der Aktivierung der
          IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von
          Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
          oder in anderen Vertragsstaaten des Abkommens über den Europäischen
          Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
          IP-Adresse an einen Server von Google in den USA übertragen und dort
          gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
          Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um
          Reports über die Webseitenaktivitäten zusammenzustellen und um weitere
          mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im
          Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse
          wird nicht mit anderen Daten von Google zusammengeführt.
        </p>

        <p>
          Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung
          der Website und in der Zusammenstellung von Reports über Aktivitäten
          auf der Website. Auf Grundlage der Nutzung der Website und des
          Internets sollen dann weitere verbundene Dienstleistungen erbracht
          werden.
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Die Verarbeitung der Daten erfolgt auf Grundlage einer Einwilligung
          des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).
        </p>

        <h3>Empfänger:</h3>

        <p>
          Empfänger der Daten ist Google als Auftragsverarbeiter. Hierfür haben
          wir mit Google den entsprechenden Auftragsverarbeitungsvertrag
          abgeschlossen.{' '}
        </p>

        <h3>Speicherdauer:</h3>

        <p>
          Die Löschung der Daten erfolgt, sobald diese für unsere
          Aufzeichnungszwecke nicht mehr erforderlich sind.
        </p>

        <h2>Drittlandtransfer: </h2>

        <p>
          Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US
          Privacy Shield unterworfen&nbsp;
          <a href="https://www.privacyshield.gov/EU-US-Framework">
            https://www.privacyshield.gov/EU-US-Framework
          </a>
          .
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
          allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff
          unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der
          Website kommen.
        </p>

        <h3>Widerruf der Einwilligung:</h3>

        <p>
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können. Sie
          können darüber hinaus die Erfassung der durch das Cookie erzeugten und
          auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem sie das unter dem folgenden Link verfügbare Browser-Plugin
          herunterladen und installieren:&nbsp;
          <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
            Browser Add On zur Deaktivierung von Google Analytics
          </a>
          .
        </p>

        <p>
          Zusätzlich oder als Alternative zum Browser-Add-On können Sie das
          Tracking durch Google Analytics auf unseren Seiten unterbinden, indem
          Sie&nbsp;
          <a href="https://www.activemind.de/datenschutz/generatoren/datenschutzerklaerung/ergebnis/#">
            diesen Link anklicken
          </a>
          . Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit
          wird die Erfassung durch Google Analytics für diese Website und für
          diesen Browser zukünftig verhindert, so lange das Cookie in Ihrem
          Browser installiert bleibt.
        </p>

        <h3>Profiling:</h3>

        <p>
          Mit Hilfe des Tracking-Tools Google Analytics kann das Verhalten der
          Besucher der Webseite bewertet und die Interessen analysiert werden.
          Hierzu erstellen wir ein pseudonymes Nutzerprofil.{' '}
        </p>

        <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
          darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der
          Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
          nachfolgend „Google“) zur Darstellung von Schriften.
        </p>

        <p>
          Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie
          hier:&nbsp;
          <a href="https://www.google.com/policies/privacy/">
            https://www.google.com/policies/privacy/
          </a>
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Rechtsgrundlage für die Einbindung von Google Webfonts und dem damit
          verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs.
          1 lit. a DSGVO).
        </p>

        <h3>Empfänger:</h3>

        <p>
          Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst
          automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei
          ist es theoretisch möglich – aktuell allerdings auch unklar ob und
          ggf. zu welchen Zwecken – dass der Betreiber in diesem Fall Google
          Daten erhebt.
        </p>

        <h3>Speicherdauer:</h3>

        <p>
          Wir erheben keine personenbezogenen Daten, durch die Einbindung von
          Google Webfonts.
        </p>

        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter&nbsp;
          <a href="https://developers.google.com/fonts/faq">
            https://developers.google.com/fonts/faq
          </a>
          &nbsp;und in der Datenschutzerklärung von Google:&nbsp;
          <a href="https://www.google.com/policies/privacy/">
            https://www.google.com/policies/privacy/
          </a>
          .
        </p>

        <h3>Drittlandtransfer:</h3>

        <p>
          Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US
          Privacy Shield unterworfen&nbsp;
          <a href="https://www.privacyshield.gov/EU-US-Framework">
            https://www.privacyshield.gov/EU-US-Framework
          </a>
          .
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich,
          noch vertraglich vorgeschrieben. Allerdings kann ohne die korrekte
          Darstellung der Inhalte von Standardschriften nicht ermöglicht werden.
        </p>

        <h3>Widerruf der Einwilligung:</h3>

        <p>
          Zur Darstellung der Inhalte wird regelmäßig die Programmiersprache
          JavaScript verwendet. Sie können der Datenverarbeitung daher
          widersprechen, indem Sie die Ausführung von JavaScript in Ihrem
          Browser deaktivieren oder einen Einbindung JavaScript-Blocker
          installieren. Bitte beachten Sie, dass es hierdurch zu
          Funktionseinschränkungen auf der Website kommen kann.
        </p>

        <h2>Eingebettete YouTube-Videos</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Auf einigen unserer Webseiten betten wir YouTube-Videos ein. Betreiber
          der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San
          Bruno, CA 94066, USA (nachfolgend „YouTube“). Wenn Sie eine Seite mit
          dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von
          YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten Sie
          besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann
          YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern
          Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen.
        </p>

        <p>
          Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die
          Hinweise über das Nutzerverhalten sammeln.
        </p>

        <p>
          Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer
          Verarbeitung durch YouTube erhalten Sie in den Datenschutzerklärungen
          des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren
          diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze
          Ihrer Privatsphäre (
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          ). Google verarbeitet Ihre Daten in den USA und hat sich dem EU-US
          Privacy Shield unterworfen
          https://www.privacyshield.gov/EU-US-Framework
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Rechtsgrundlage für die Einbindung von YouTube und dem damit
          verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs.
          1 lit. a DSGVO).
        </p>

        <h3>Empfänger:</h3>

        <p>
          Der Aufruf von YouTube löst automatisch eine Verbindung zu Google aus.
        </p>

        <h3>Speicherdauer und Widerruf der Einwilligung:</h3>

        <p>
          Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert
          hat, wird auch beim Anschauen von YouTube-Videos mit keinen solchen
          Cookies rechnen müssen. YouTube legt aber auch in anderen Cookies
          nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies
          verhindern, so müssen Sie das Speichern von Cookies im Browser
          blockieren.
        </p>

        <p>
          Weitere Informationen zum Datenschutz bei „YouTube“ finden Sie in der
          Datenschutzerklärung des Anbieters unter:&nbsp;
          <a href="https://www.google.de/intl/de/policies/privacy/">
            https://www.google.de/intl/de/policies/privacy/
          </a>
        </p>

        <h3>Drittlandtransfer:</h3>

        <p>
          Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US
          Privacy Shield unterworfen&nbsp;
          <a href="https://www.privacyshield.gov/EU-US-Framework">
            https://www.privacyshield.gov/EU-US-Framework
          </a>
          .
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
          allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff
          unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der
          Website kommen.
        </p>

        <h2>Google AdWords</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Unsere Webseite nutzt das Google Conversion-Tracking.
          Betreibergesellschaft der Dienste von Google AdWords ist die Google
          LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Sind Sie
          über eine von Google geschaltete Anzeige auf unsere Webseite gelangt,
          wird von Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das
          Cookie für Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine
          von Google geschaltete Anzeige klickt.
        </p>

        <p>
          Besucht der Nutzer bestimmte Seiten unserer Website und das Cookie ist
          noch nicht abgelaufen, können wir und Google erkennen, dass der Nutzer
          auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
          Jeder Google AdWords-Kunde erhält ein anderes Cookie. Cookies können
          somit nicht über die Websites von AdWords-Kunden nachverfolgt werden.
          Die mithilfe des Conversion-Cookies eingeholten Informationen dienen
          dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich
          für Conversion-Tracking entschieden haben. Die Kunden erfahren die
          Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu
          einer mit einem Conversion-Tracking-Tag versehenen Seite
          weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
          denen sich Nutzer persönlich identifizieren lassen.
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Rechtsgrundlage für die Einbindung von Google AdWords und dem damit
          verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs.
          1 lit. a DSGVO).
        </p>

        <h3>Empfänger:</h3>

        <p>
          Bei jedem Besuch unsere Webseite werden personenbezogene Daten,
          einschließlich Ihrer IP-Adresse an Google in die USA übertragen. Diese
          personenbezogenen Daten werden durch Google gespeichert. Google gibt
          diese über das technische Verfahren erhobenen personenbezogenen Daten
          unter Umständen an Dritte weiter.
        </p>

        <p>
          Unser Unternehmen enthält keine Informationen von Google, mittels
          derer die betroffene Person identifiziert werden könnte.
        </p>

        <h3>Speicherdauer:</h3>

        <p>
          Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht
          der persönlichen Identifizierung.
        </p>

        <h3>Drittlandtransfer:</h3>

        <p>
          Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US
          Privacy Shield unterworfen&nbsp;
          <a href="https://www.privacyshield.gov/EU-US-Framework">
            https://www.privacyshield.gov/EU-US-Framework
          </a>
          .
        </p>

        <h3>Widerruf der Einwilligung:</h3>

        <p>
          Möchten Sie nicht am Tracking teilnehmen, können Sie das hierfür
          erforderliche Setzen eines Cookies ablehnen – etwa per
          Browser-Einstellung, die das automatische Setzen von Cookies generell
          deaktiviert oder Ihren Browser so einstellen, dass Cookies von der
          Domain „googleleadservices.com“ blockiert werden.
        </p>

        <p>
          Bitte beachten Sie, dass Sie die Opt-out-Cookies nicht löschen dürfen,
          solange Sie keine Aufzeichnung von Messdaten wünschen. Haben Sie alle
          Ihre Cookies im Browser gelöscht, müssen Sie das jeweilige Opt-out
          Cookie erneut setzen.
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
          allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff
          unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der
          Website kommen.
        </p>

        <h2>Einsatz von Google Remarketing</h2>

        <h3>Art und Zweck der Verarbeitung:</h3>

        <p>
          Diese Webseite verwendet die Remarketing-Funktion der Google Inc.
          Betreibergesellschaft der Dienste von Google Remarketing ist die
          Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
          (nachfolgend „Google“).
        </p>

        <p>
          Die Funktion dient dazu, Webseitenbesuchern innerhalb des
          Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu
          präsentieren. Im Browser des Webseitenbesuchers wird ein sog. „Cookie“
          gespeichert, der es ermöglicht, den Besucher wiederzuerkennen, wenn
          dieser Webseiten aufruft, die dem Werbenetzwerk von Google angehören.
          Auf diesen Seiten können dem Besucher Werbeanzeigen präsentiert
          werden, die sich auf Inhalte beziehen, die der Besucher zuvor auf
          Webseiten aufgerufen hat, die die Remarketing Funktion von Google
          verwenden.
        </p>

        <h3>Rechtsgrundlage:</h3>

        <p>
          Rechtsgrundlage für die Einbindung von Google Remarketing und dem
          damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art.
          6 Abs. 1 lit. a DSGVO).
        </p>

        <h3>Empfänger:</h3>

        <p>
          Bei jedem Besuch unsere Webseite werden personenbezogene Daten,
          einschließlich Ihrer IP-Adresse an Google in die USA übertragen. Diese
          personenbezogenen Daten werden durch Google gespeichert. Google gibt
          diese über das technische Verfahren erhobenen personenbezogenen Daten
          unter Umständen an Dritte weiter.
        </p>

        <p>
          Unser Unternehmen enthält keine Informationen von Google, mittels
          derer die betroffene Person identifiziert werden könnte.
        </p>

        <h3>Widerruf der Einwilligung:</h3>

        <p>
          Nach eigenen Angaben erhebt Google bei diesem Vorgang keine
          personenbezogenen Daten. Sollten Sie die Funktion Remarketing von
          Google dennoch nicht wünschen, können Sie diese grundsätzlich
          deaktivieren, indem Sie die entsprechenden Einstellungen unter&nbsp;
          <a href="https://support.google.com/adwordspolicy/answer/143465">
            https://support.google.com/adwordspolicy/answer/143465
          </a>
          &nbsp;vornehmen. Alternativ können Sie den Einsatz von Cookies für
          interessenbezogene Werbung über die Werbenetzwerkinitiative
          deaktivieren, indem Sie den Anweisungen unter&nbsp;
          <a href="http://www.networkadvertising.org/managing/opt_out.asp">
            http://www.networkadvertising.org/managing/opt_out.asp
          </a>
          folgen.
        </p>

        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>

        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
          allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff
          unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der
          Website kommen.
        </p>

        <h2>SSL-Verschlüsselung</h2>

        <p>
          Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
          verwenden wir dem aktuellen Stand der Technik entsprechende
          Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
        </p>

        <h2>Änderung unserer Datenschutzbestimmungen</h2>

        <p>
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entspricht oder um
          Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
          z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
          dann die neue Datenschutzerklärung.
        </p>

        <p>
          <em>Die Datenschutzerklärung wurde mit dem&nbsp;</em>
          <em>
            <a
              href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Datenschutzerklärungs-Generator der activeMind AG erstellt
            </a>
            &nbsp;(Version 2018-09-24).
          </em>
        </p>
      </>
    </HiddenPageTemplate>
  );
};

export default DataPage;
